import micromark from "micromark";

export const parseImage = (imageInfo) => {
  if (!imageInfo) return null;
  const { alt = "", childImageSharp, url, src, publicURL } = imageInfo;

  if (!!src && !!src.childImageSharp)
    return {
      alt,
      src: src.childImageSharp.fluid.src,
      fluid: src.childImageSharp.fluid,
    };
  if (childImageSharp) return { alt, src: childImageSharp.fluid.src };
  if (!!src && typeof image === "string") return { alt, src };

  if (url) return { alt, src: url };

  if (publicURL) return { alt, src: publicURL };

  return { src, alt };
};

export const filterPosts = (posts, category, limit, excludeId) => {
  let filteredPosts = posts;
  if (category)
    filteredPosts = filteredPosts.filter(
      (post) =>
        post.category && post.category.toLowerCase() === category.toLowerCase()
    );
  if (excludeId)
    filteredPosts = filteredPosts.filter((post) => post.id !== excludeId);
  if (limit) filteredPosts = filteredPosts.slice(0, limit);
  return filteredPosts;
};

export const getStringsFromVersion = (strings, version) => {
  let selectedStrings = {};
  Object.keys(strings).forEach(
    (key) => (selectedStrings[key] = strings[key][version])
  );
  return selectedStrings;
};

export const toHTML = (value) => micromark(value);

export const getYoutubeIDFromURL = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url && url.match(regExp);

  return match && match[7].length === 11 ? match[7] : null;
};

export const generateYoutubeEmbed = (
  id
) => `<div style="position: relative; padding-bottom: 56.25%; padding-top: 25px; margin-bottom: 16px; height: 0;">
  <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" src="https://www.youtube.com/embed/${id}" frameBorder="0"></iframe>
</div>`;

export const handleHttpError = async (response) => {
  if (!response.ok) {
    throw { status: response.status, ...(await response.json()) };
  }

  return response;
};
