import { Button, Label, Select, Spinner, TextInput } from "@enpowered/ui";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import MultiLineText from "../MultiLineText";
import { OPTION_NAMES, OPTIONS, pingWebhook } from "./utils";

const WebhookTest = ({ successMessage }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const onSuccess = () => {
    setIsLoading(false);
    setIsSuccess(true);
  };
  const onError = (error) => {
    setIsLoading(false);
    return (
      error &&
      error.errors &&
      error.errors[0] &&
      setError(error.errors[0].detail)
    );
  };

  const onSubmit = (values) => {
    setError("");
    setIsLoading(true);
    return pingWebhook(values, onSuccess, onError);
  };
  const resetForm = () => setIsSuccess(false);
  return (
    <div className="w-full max-w-2xl mx-auto">
      {isSuccess ? (
        <div className="bg-en-yellow-300 shadow-lg text-en-gray-900 p-8 rounded-lg text-center">
          <h2 className="font-bold text-3xl text-center">Success</h2>
          <div className="pt-8 pb-4">
            <MultiLineText text={successMessage} />
          </div>
          <Button onClick={resetForm} theme="dark">
            Send Another Test
          </Button>
        </div>
      ) : (
        <div className="bg-white shadow-lg text-en-gray-900 p-8 rounded-lg">
          <h2 className="font-bold text-3xl text-center">Setup Test Webhook</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-4">
              <Label htmlFor="url" className="font-bold text-base">
                URL
              </Label>
              <TextInput
                className="w-full"
                name="url"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
                    message: "Invalid URL",
                  },
                })}
              />
              {errors && errors.url && (
                <Label className="text-red-600 text-xs">
                  {errors.url.message}
                </Label>
              )}
            </div>
            <div className="xl:flex">
              <div className="w-full xl:w-1/2 py-4 xl:pr-4">
                <Label htmlFor="present" className="font-bold text-base">
                  Present Value
                </Label>
                <Select className="w-full" name="present" ref={register}>
                  {OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option} | {OPTION_NAMES[option]}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="w-full xl:w-1/2 py-4 xl:pl-4">
                <Label htmlFor="future" className="font-bold text-base">
                  Future Value
                </Label>
                <Select className="w-full" name="future" ref={register}>
                  {OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option} | {OPTION_NAMES[option]}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex justify-center py-4">
              <Button
                size="narrow"
                disabled={isLoading}
                className="flex items-center justify-center"
              >
                {isLoading ? <Spinner /> : "Send Test"}
              </Button>
            </div>
            {error && (
              <Label className="text-red-600 text-xs text-center">
                {error}
              </Label>
            )}
          </form>
        </div>
      )}

      <div className="text-xs text-center my-8">
        This tool is for testing only.
      </div>
    </div>
  );
};

WebhookTest.propTypes = {
  successMessage: PropTypes.string,
};

export default WebhookTest;
