import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { toHTML } from "../../utils";
import { HTMLContent } from "../Content";

const RichTextSection = ({ content, pageLayout }) => {
  const { richText, code, inlineCode } = content;
  const isTwoColumn = pageLayout === "two-column";

  const sectionWrapperClass = classNames(
    {
      "md:flex": isTwoColumn,
    },
    "mx-auto w-full"
  );

  const textWrapperClass = classNames(
    {
      "md:w-1/2 md:px-24": isTwoColumn,
      "max-w-screen-md mx-auto": !isTwoColumn,
      "max-w-screen-md md:max-w-full mx-auto md:mx-0": inlineCode,
    },
    "w-full py-8 px-8"
  );

  const codeWrapperClass = classNames({
    "md:block md:w-1/2 md:p-0 md:bg-en-gray-900": isTwoColumn,
    "max-w-screen-md md:mx-auto pb-12": !isTwoColumn,
    hidden: isTwoColumn && !inlineCode,
    "w-full px-8": !isTwoColumn || inlineCode,
  });

  const codeClass = classNames(
    {
      "md:py-8 md:pl-12 md:pr-20": isTwoColumn,
      "rounded p-8": !isTwoColumn,
      "rounded md:rounded-0 p-8": inlineCode,
    },
    "bg-en-gray-900 text-white"
  );

  return (
    <div className={sectionWrapperClass}>
      <div className={textWrapperClass}>
        {richText && (
          <HTMLContent content={toHTML(richText)} isTwoColumn={isTwoColumn} />
        )}
      </div>

      <div className={codeWrapperClass}>
        <div className={codeClass}>
          {code && (
            <HTMLContent
              content={toHTML(code)}
              isCodePreview
              isTwoColumn={isTwoColumn}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RichTextSection;
