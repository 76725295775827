import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { HTMLContent } from "../components/Content";
import PageSection from "../components/PageSection";
import Wrapper from "../components/Wrapper";
import { toHTML } from "../utils";

export const PageTemplate = ({ sections, pageLayout, isCMSPreview }) => {
  const codeSamples =
    sections &&
    sections
      .filter(({ code, inlineCode }) => !!code && !inlineCode)
      .map(({ code }) => code);
  return (
    <>
      {sections &&
        sections.length > 0 &&
        sections.map((section, index) => (
          <PageSection
            pageLayout={pageLayout}
            key={index}
            content={section}
            isCMSPreview={isCMSPreview}
          />
        ))}
      {codeSamples && codeSamples.length > 0 && pageLayout === "two-column" && (
        <div className="md:hidden p-8">
          <div className="bg-en-gray-900 text-white p-8 w-full rounded">
            {codeSamples.map((codeSample, i) => (
              <HTMLContent key={i} content={toHTML(codeSample)} isCodePreview />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
PageTemplate.propTypes = {
  pageLayout: PropTypes.string,
  isCMSPreview: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object),
};

const CustomPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const { title, sections, pageLayout, metadata } = frontmatter;

  const meta = {
    title: title,
    slug: frontmatter.slug || fields.slug,
    description: metadata && metadata.description,
    image: metadata && metadata.previewImage && metadata.previewImage.publicURL,
  };

  return (
    <Wrapper meta={meta} isFullWidth>
      <PageTemplate pageLayout={pageLayout} sections={sections} />
    </Wrapper>
  );
};

export default CustomPage;

export const pageQuery = graphql`
  query CustomPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        slug
        title
        pageLayout
        sections {
          type
          heading
          description
          id
          richText
          code
          inlineCode
          successMessage
          hubspotID
          cta {
            link
            text
          }
        }
        metadata {
          description
          previewImage {
            publicURL
          }
        }
      }
    }
  }
`;
