import { Text } from '@enpowered/ui';
import PropTypes from 'prop-types';
import React from 'react';

const MultiLineText = ({ text, ...props }) => {
  const lines = text && text.split(/\n/); 
  return lines && lines.length > 0 ? lines.map((line, index) => (
    <Text key={index} {...props}>{line}</Text>
  )) : null;
};

MultiLineText.propTypes = {
  text: PropTypes.string,
};

export default MultiLineText;
