import "./HubspotForm.css";

import PropTypes from "prop-types";
import React from "react";
import ReactHubspotForm from "react-hubspot-form";

const HubspotForm = ({ portalId = "2076831", formId }) => {
  return (
    <div className="hubspot-form w-full max-w-xl mx-auto">
      <ReactHubspotForm
        portalId={portalId}
        formId={formId}
        submitButtonClass="btn yellow-gradient-button"
        loading={<div>Loading...</div>}
      />
    </div>
  );
};

HubspotForm.propTypes = {
  portalId: PropTypes.string,
  formId: PropTypes.string,
};

export default HubspotForm;
