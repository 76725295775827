import { handleHttpError } from "../../utils";

export const OPTIONS = ["-10", "0", "10", "20", "30", "40", "50"];

export const OPTION_NAMES = {
  "-10": "Negative",
  "0": "Normal",
  "10": "Very Small Spike",
  "20": "Small Spike",
  "30": "Moderate Spike",
  "40": "Major Spike",
  "50": "Mandatory",
};

const apiRoot = process.env.GATSBY_API_ROOT;

// TODO Replace with Prod
const TEST_ENDPOINT = `${apiRoot}/webhooks/test`;

export const pingWebhook = ({ url, present, future }, onSuccess, onError) => {
  const date = new Date().getTime() + 1000 * 60 * 60 * 3;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      data: {
        webhookUrl: url,
        requiresAuth: false,
        webhookBody: {
          data: [
            {
              id: "ps_test83273892",
              info: {
                name: "EnPowered Test",
                type: "primary",
              },
              signals: [
                {
                  present: {
                    number: parseInt(present),
                    name: OPTION_NAMES[present],
                  },
                  future: {
                    number: parseInt(future),
                    name: OPTION_NAMES[future],
                    start_period: new Date(date).toISOString(),
                  },
                },
              ],
            },
          ],
        },
      },
    }),
  };
  return fetch(TEST_ENDPOINT, requestOptions)
    .then(handleHttpError)
    .then((res) => res.json())
    .then(onSuccess)
    .catch(onError);
};
