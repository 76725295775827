import classNames from "classnames";
import parse, { domToReact } from "html-react-parser";
import PropTypes from "prop-types";
import React from "react";
import Highlight from "react-highlight";

import { generateYoutubeEmbed } from "./../utils";
import ClickToCopy from "./ClickToCopy";

const wrapperClass = (isCodePreview, isTwoColumn) =>
  classNames({
    "code-preview": isCodePreview,
    "md:mt-8": isCodePreview && isTwoColumn,
    "markdown-preview": !isCodePreview,
  });

const replace = (node) => {
  if (node.type === "tag" && node.name === "pre") {
    console.log(node.children);
    return (
      <Highlight innerHTML={false} element="pre">
        {domToReact(node.children)}
      </Highlight>
    );
  }
  if (node.type === "tag" && node.attribs["data-type"] === "click-to-copy")
    return <ClickToCopy content={node.attribs["data-content"]} />;
};

export const HTMLContent = ({ content = "", isCodePreview, isTwoColumn }) => {
  const updatedContent = content
    .replace(/<p>% youtube (\S+) %<\/p>/gm, generateYoutubeEmbed("$1"))
    .replace(
      /<p>% clickToCopy (\S+) %<\/p>/gm,
      `<div data-type="click-to-copy" data-content="$1">$1</div>`
    );

  return (
    <div className={wrapperClass(isCodePreview, isTwoColumn)}>
      {parse(updatedContent, { replace })}
    </div>
  );
};

const Content = ({ content, isCodePreview, isTwoColumn }) => (
  <div className={wrapperClass(isCodePreview, isTwoColumn)}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.node,
  isCodePreview: PropTypes.bool,
  className: PropTypes.string,
};

HTMLContent.propTypes = {
  content: PropTypes.node,
  isCodePreview: PropTypes.bool,
  className: PropTypes.string,
};

export default Content;
