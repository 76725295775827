import PropTypes from "prop-types";
import React from "react";

import CallToActionSection from "./CallToActionSection";
import TextAndCodeSection from "./TextAndCodeSection";
import TextSection from "./TextSection";
import WebhookTestingSection from "./WebhookTestingSection";

const PageSection = ({ as: El = "section", content, pageLayout }) => {
  return (
    <El id={content.id}>
      {content.type === "callToAction" && (
        <CallToActionSection content={content} pageLayout={pageLayout} />
      )}
      {content.type === "textSection" && (
        <TextSection content={content} pageLayout={pageLayout} />
      )}
      {content.type === "textAndCodeSection" && (
        <TextAndCodeSection content={content} pageLayout={pageLayout} />
      )}
      {content.type === "webhookTestingSection" && (
        <WebhookTestingSection content={content} pageLayout={pageLayout} />
      )}
    </El>
  );
};

PageSection.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  content: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  pageLayout: PropTypes.string,
};

export default PageSection;
