import "./click-to-copy.css";

import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import CopiedIcon from "../../img/copied.svg";
import CopyIcon from "../../img/copy.svg";

const ClickToCopy = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () =>
    navigator &&
    navigator.clipboard.writeText(content).then(() => setCopied(true));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const buttonClass = classnames(
    {
      ["bg-en-gray-100"]: !copied,
      ["bg-en-yellow-400"]: copied,
    },
    "shadow-sm rounded-r w-20 px-2 flex-shrink-0"
  );

  return (
    <div className="flex mb-6">
      <div className="copy-wrapper pt-4 pb-1 relative overflow-x-hidden bg-en-gray-800 rounded-l">
        <div className="copy-contents font-mono text-white pr-8 pl-4 pb-2 whitespace-no-wrap overflow-x-auto w-full relative block text-xs">
          {content}
        </div>
      </div>
      <button onClick={copyToClipboard} className={buttonClass}>
        <div className="mx-auto">
          {copied ? (
            <img src={CopiedIcon} alt="" className="h-4" />
          ) : (
            <img src={CopyIcon} alt={"Copy"} className="h-6" />
          )}
        </div>

        <span className="text-xs font-bold">{copied ? "Copied!" : " "}</span>
      </button>
    </div>
  );
};

ClickToCopy.propTypes = {
  content: PropTypes.string,
};

export default ClickToCopy;
