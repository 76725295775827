import { Button, H } from "@enpowered/ui";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import HubspotForm from "../HubspotForm";
import MultiLineText from "../MultiLineText";

const CallToActionSection = ({ content, pageLayout }) => {
  const { heading, description, hubspotID, cta } = content;
  const sectionClass = classNames([
    "flex",
    "flex-col",
    "items-center",
    "max-w-2xl",
    "mx-auto",
    "px-6 py-12",
  ]);
  return (
    <section id="call-to-action" className={sectionClass}>
      <div className="max-w-2xl">
        {heading && (
          <H level={2} align="left" className="mb-4">
            {heading}
          </H>
        )}
        {description && (
          <MultiLineText text={description} align="left" className="mb-12" />
        )}
      </div>
      {hubspotID && (
        <div className="bg-white rounded-md shadow-lg w-full max-w-2xl p-8">
          <HubspotForm formId={hubspotID} />
        </div>
      )}
      {cta && cta.text && cta.link && (
        <Button size="narrow" as={Link} to={cta.link}>
          {cta.text}
        </Button>
      )}
    </section>
  );
};

CallToActionSection.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  heading: PropTypes.string,
  description: PropTypes.string,
  hubspotID: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default CallToActionSection;
