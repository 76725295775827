import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { toHTML } from "../../utils";
import { HTMLContent } from "../Content";

const TextSection = ({ content, pageLayout }) => {
  const { richText } = content;
  const isTwoColumn = pageLayout === "two-column";

  const textWrapperClass = classNames(
    {
      "max-w-screen-md md:px-4": !isTwoColumn,
      "md:w-7/12 md:pr-24 md:px-12": isTwoColumn,
    },
    "w-full py-8 px-8 mx-auto"
  );

  const codePlaceholderClass = classNames(
    {
      "md:block": isTwoColumn,
    },
    "hidden bg-en-gray-900 w-5/12"
  );

  return (
    <div className="mx-auto flex w-full">
      <div className={textWrapperClass}>
        {richText && (
          <HTMLContent content={toHTML(richText)} isTwoColumn={isTwoColumn} />
        )}
      </div>
      <div className={codePlaceholderClass} />
    </div>
  );
};

TextSection.propTypes = {
  pageLayout: PropTypes.string,
  content: PropTypes.shape({
    richText: PropTypes.string,
  }),
};

export default TextSection;
